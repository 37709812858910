import {
  FETCH_SITES_FAILURE,
  FETCH_SITES_REQUEST,
  FETCH_SITES_SUCCESS,
} from '../types/sites';
import builder from './builder';

const initialState = {
  loading: false,
  sites: [],
  error: '',
};

const Sites = {
  [FETCH_SITES_REQUEST]: () => {
    return {
      loading: true,
    };
  },
  [FETCH_SITES_SUCCESS]: ({ state, action }) => {
    return {
      loading: false,
      sites: action.payload,
      error: '',
    };
  },
  [FETCH_SITES_FAILURE]: ({ action }) => {
    return {
      loading: false,
      error: action.payload,
    };
  },
};

export default builder(initialState, Sites);

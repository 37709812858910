import React from 'react';
import Api from '../api/Api';
import { View, Text, StyleSheet } from 'react-native';
import CardBlock from '../components/CardBlock';
import { GridLayout, Row, Col } from '../components/Grid';
import SubHeader from '../components/SubHeader';
import moment from 'moment';
import CustomMap from '../components/Map';
export default class Work extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      work: {},
    };
  }

  getOneWork() {
    Api.get('client/works/' + this.props.match.params.id)
      .then((res) => {
        const data = res.data.data[0];
        console.log(data);
        this.setState({ work: data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getOneWork();
  }

  render() {
    console.log('works', this.state.work);
    return (
      <View style={styles.container}>
        <SubHeader
          name={'WORK ' + this.state.work.id}
          description="lorem ipsum "
        />
        <View style={styles.gridView}>
          <GridLayout>
            <Row>
              <Col lg={4} md={12}>
                <CardBlock displayTitle="General">
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Client:</Text>
                    <Text style={styles.textValue}>
                      {this.state.work.client}
                    </Text>
                  </View>
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Name:</Text>
                    <Text style={styles.textValue}>{this.state.work.name}</Text>
                  </View>

                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Status:</Text>
                    <Text style={styles.textValue}>
                      {this.state.work.status}
                    </Text>
                  </View>

                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Description:</Text>
                    <Text style={styles.textValue}>
                      {this.state.work.description}
                    </Text>
                  </View>

                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Notes:</Text>
                    <Text style={styles.textValue}>
                      {this.state.work.notes}
                    </Text>
                  </View>
                </CardBlock>
              </Col>
              <Col lg={4} md={12}>
                <CardBlock displayTitle="Dates">
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Date Started:</Text>
                    <Text style={styles.textValue}>
                      {moment(this.state.work.date_staff_informed).format(
                        'llll'
                      )}
                    </Text>
                  </View>
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Date Staff Informed:</Text>
                    <Text style={styles.textValue}>
                      {moment(this.state.work.date_staff_informed).format(
                        'llll'
                      )}
                    </Text>
                  </View>
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Date Staff acknowledged:</Text>
                    <Text style={styles.textValue}>
                      {moment(this.state.work.date_staff_informed).format(
                        'llll'
                      )}
                    </Text>
                  </View>
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Date Staff Visited:</Text>
                    <Text style={styles.textValue}>
                      {moment(this.state.work.date_staff_informed).format(
                        'llll'
                      )}
                    </Text>
                  </View>
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Date Staff Resolved:</Text>
                    <Text style={styles.textValue}>
                      {moment(this.state.work.date_staff_informed).format(
                        'llll'
                      )}
                    </Text>
                  </View>
                </CardBlock>
              </Col>
            </Row>
          </GridLayout>
          <CustomMap />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
    backgroundColor: '#e7e7e7',
  },

  gridView: {
    alignSelf: 'center',
    marginTop: 40,
  },

  text: {
    fontSize: 16,
    color: '#737477',
    fontFamily: 'CFDinLight',
  },
  textValue: {
    fontSize: 16,
    color: '#414146',
    fontWeight: 800,
    fontFamily: 'Manrope-Regular',
  },
  wrapper: {
    marginVertical: 10,
  },
});

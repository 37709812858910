import React from 'react';
import { View, StyleSheet, Text } from 'react-native-web';
import EStyleSheet from 'react-native-extended-stylesheet';

export default function SubHeader(props) {
  return (
    <View style={styles.header}>
      <View style={[styles.row, props.row]}>
        <View style={styles.children}>{props.children}</View>
        <View style={styles.container}>
          <Text style={[styles.displayTitle, props.styleTitleText]}>
            {props.name}
          </Text>
          <Text style={[styles.description, props.styleDescription]}>
            {props.description}
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = EStyleSheet.create({
  header: {
    width: '100%',
    height: 200,
    backgroundColor: '#313c77',
  },
  displayTitle: {
    fontSize: 64,
    fontFamily: 'CFDinBold ',
    color: '#ffffff',
    paddingBottom: 15,
    textTransform: 'uppercase',
  },
  description: {
    fontSize: 18,
    fontWeight: 1,
    fontFamily: 'Manrope',
    color: '#ffffff',
    lineHeight: 12,
  },
  container: {
    paddingLeft: 50,
  },
  row: {
    flexDirection: 'row',
    paddingLeft: 100,
  },
  '@media (min-width:451) and (max-width:768)': {
    children: {
      marginLeft: 0,
    },
    displayTitle: {
      marginLeft: 0,
      fontSize: 30,
    },
    row: {
      marginLeft: 0,
    },
    description: {
      marginLeft: 0,
    },
  },
  '@media (min-width:361) and (max-width:450)': {
    children: {
      marginLeft: 0,
    },
    displayTitle: {
      marginLeft: 0,
      fontSize: 30,
    },
    row: {
      marginLeft: 0,
      right: 80,
    },
    description: {
      textAlign: 'center',
    },
  },
  '@media (max-width:360)': {
    children: {},
    displayTitle: {
      fontSize: 30,
    },
    row: {
      marginLeft: 80,
    },
    description: {
      textAlign: 'center',
    },
  },
});

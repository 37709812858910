import {
  FETCH_ACCOUNT_REQUEST,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAILURE,
} from '../types/account';
import builder from './builder';

const initialState = {
  loading: false,
  account: {},
  error: '',
};

const Account = {
  [FETCH_ACCOUNT_REQUEST]: () => {
    return {
      loading: true,
    };
  },
  [FETCH_ACCOUNT_SUCCESS]: ({ state, action }) => {
    return {
      loading: false,
      account: action.payload,
      error: '',
    };
  },
  [FETCH_ACCOUNT_FAILURE]: ({ action }) => {
    return {
      loading: false,
      error: action.payload,
    };
  },
};

export default builder(initialState, Account);

import React from 'react';
import { View, StyleSheet } from 'react-native-web';
import Form from '../components/Form';
import SubHeader from '../components/SubHeader';
import { connect } from 'react-redux';
import EStyleSheet from 'react-native-extended-stylesheet';

class Profile extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <View style={styles.container}>
        <SubHeader
          titleText={styles.titleText}
          name={`${this.props.account.account.first_name}  ${this.props.account.account.last_name}`}
          description="Lorem ipsum dolor set amet "
        />
        <Form />
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps)(Profile);

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: '#ffffff',
  },

  titleText: {
    flex: 1,
    fontSize: 28,
    lineHeight: 10,
    color: '#ffffff',
    fontWeight: 'bold',
    fontFamily: 'CFDinBold',
  },
});

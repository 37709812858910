import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  useWindowDimensions,
  Dimensions,
} from 'react-native';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';
import Modal from './Modals/Modal';
import Button from './Button';
import InputText from '../components/InputText';
import * as accountActions from '../redux/actions/account';
import * as sitesActions from '../redux/actions/sites';
import { connect } from 'react-redux';
import Picker from './PickerChoiceSelection';
import moment from 'moment';
import EStyleSheet from 'react-native-extended-stylesheet';
import Api from '../api/Api';

const Layout = (props) => {
  if (!cookie.load('token')) {
    window.location.replace('/login');
  }

  useEffect(() => {
    props.fetchAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let history = useHistory();
  const [toggleState, setToggleState] = useState();
  const [dashboardState, setToggleDashboard] = useState(true);
  const [active, setActive] = useState(false);
  const [optionsState, setOptionsState] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [siteId, setSiteId] = useState();
  const [sites, setSites] = useState([]);
  const [ticket, setTicket] = useState();
  const [date, setDate] = useState();

  const window = useWindowDimensions();

  React.useEffect(() => {
    if (window.width > 768) {
      setToggleState(true);
    } else {
      setToggleState(false);
    }
  }, [window]);

  function toggle() {
    setToggleState(!toggleState);
  }

  function openModal() {
    setIsOpen(!modalIsOpen);
  }

  const toggleDashboard = () => {
    setToggleDashboard(!dashboardState);
  };

  const linkHighlight = (str) => {
    setActive(true);
    if (window.width <= 768) {
      toggle();
    }
    history.push(str);
  };

  const toggleOptions = () => {
    setOptionsState(!optionsState);
  };

  const renderDashboardList = () => {
    return (
      <View style={{ marginBottom: 45, height: 70 }}>
        <InnerItem
          name="Main Dashboard"
          onPress={() => linkHighlight('/dashboard')}
        />
        <InnerItem
          name="Analytics"
          onPress={() => linkHighlight('/analytics')}
        />
        <InnerItem
          name="Statistics"
          onPress={() => linkHighlight('/statistics')}
        />
      </View>
    );
  };

  const logout = async () => {
    await Api.get('/account/logout');
    window.location.replace('/login');
  };

  const renderOptions = () => {
    return (
      <View style={{ marginLeft: 25 }}>
        <TouchableOpacity style={styles.logout} onPress={logout}>
          <Text style={{ color: 'white' }}>LOGOUT</Text>
        </TouchableOpacity>
      </View>
    );
  };
  useEffect(() => {
    const fetchSites = async () => {
      const res = await Api.get('/admin/sites/');
      let data = res.data.data;
      let idsArray = [];
      data.map((item) => {
        let id = item.id;
        idsArray.push(id);
      });
      setSites(idsArray);
    };
    fetchSites();
  }, []);

  function Item({ name, unicode, onPress }) {
    return (
      <TouchableOpacity style={styles.item} onPress={onPress}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <Text style={styles.text}>{name}</Text>
          <Text style={{ color: '#0bb975', marginLeft: 20, bottom: 40 }}>
            {unicode}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }

  const handleTicket = (text) => {
    setTicket(text);
  };

  const sendTicket = async () => {
    const now = new Date();
    setDate(now);
    await Api.post('client/tickets', {
      site_id: siteId,
      client_request: ticket,
      date_submitted: '2020-12-11',
    }).then((res) => {
      openModal();
    });
  };

  function InnerItem({ name, unicode, onPress }) {
    return (
      <TouchableOpacity style={styles.innerItem} onPress={onPress}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <Text
            style={{
              fontSize: 14,
              color: '#24242a',
              lineHeight: 24,
              paddingVertical: 3,
            }}
          >
            {name}
          </Text>
          <Text>{unicode}</Text>
        </View>
      </TouchableOpacity>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <View style={[styles.menuBar, styles.menuResponsive]}>
          <Image
            source={require('../assets/images/Logo.png')}
            style={[styles.image]}
          />
          <TouchableOpacity onPress={() => toggle()} style={styles.button}>
            <Text
              style={[
                styles.closeButton,
                {
                  color: '#ffffff',
                  fontSize: 40,
                  paddingHorizontal: 10,
                },
              ]}
            >
              X
            </Text>
          </TouchableOpacity>
        </View>

        {toggleState && (
          <View style={[styles.container, styles.column]}>
            <Item name="Dashboard" unicode="⏷" onPress={toggleDashboard} />
            {dashboardState ? null : renderDashboardList()}
            <Item name="Sites" onPress={() => linkHighlight('/sites')} />
            <Item name="Works" onPress={() => linkHighlight('/works')} />
            <Item name="Profile" onPress={() => linkHighlight('/profile')} />
          </View>
        )}

        <View style={{ flex: 1, zIndex: -1 }}>
          <View style={styles.header}>
            <View style={styles.headerItems}>
              <TouchableOpacity onPress={() => openModal()}>
                <Text style={[styles.headerText, { paddingBottom: 2 }]}>
                  SUPPORT
                </Text>
              </TouchableOpacity>
              <Modal
                onBackdropPress={() => openModal()}
                isVisible={modalIsOpen}
              >
                <View style={{ bottom: 50 }}>
                  <InputText
                    onChangeText={(text) => handleTicket(text)}
                    style={{ width: '100%' }}
                  />
                </View>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                  <Button
                    style={{ backgroundColor: '#0bb975' }}
                    onPress={() => sendTicket()}
                  />
                  <Picker
                    items={[
                      { label: 'please select', value: 0 },
                      ...props.sites.sites.map((item) => ({
                        label: item.name,
                        value: item.id,
                      })),
                    ]}
                    style={styles.pickerDimensions}
                    selectedValue={siteId}
                    onValueChange={(itemValue, itemIndex) =>
                      setSiteId(itemValue)
                    }
                  />
                </View>
              </Modal>

              <TouchableOpacity>
                <Text style={styles.headerText}>
                  EN
                  <Text
                    style={{
                      color: '#0bb975',
                      marginLeft: 5,
                      width: 11,
                    }}
                  >
                    ⏷
                  </Text>
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={toggleOptions}>
                <Text style={styles.headerText}>
                  {props.account.account.first_name +
                    ' ' +
                    props.account.account.last_name}
                  <Text
                    style={{
                      color: '#0bb975',
                      marginLeft: 5,
                      width: 11,
                    }}
                  >
                    ⏷
                  </Text>
                </Text>
                {optionsState ? null : renderOptions()}
              </TouchableOpacity>
            </View>
          </View>
          {props.children}
        </View>
      </View>
    </View>
  );
};
const mapStateToProps = (state) => ({
  sites: state.sitesReducer,
  account: state.accountReducer,
});

const mapActions = {
  fetchAccount: accountActions.fetchAccount,
  fetchSites: sitesActions.fetchSites,
};

export default connect(mapStateToProps, mapActions)(Layout);

const styles = EStyleSheet.create({
  container: {
    width: 390,
    backgroundColor: '#dadada',
    paddingLeft: 140,
    paddingTop: 180,
  },

  item: {
    paddingVertical: 20,
  },

  innerItem: {
    width: 200,
  },

  header: {
    height: 150,
    backgroundColor: '#313c77',
    zIndex: -2,
  },

  headerItems: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginHorizonal: 5,
    paddingTop: 75,
    paddingRight: 150,
  },

  text: {
    fontSize: 24,
    letterSpacing: 1,
    lineHeight: 24,
    color: '#0bb975',
    fontWeight: 'bold',
    fontFamily: 'CFDinBold',
    textTransform: 'uppercase',
  },

  headerText: {
    color: 'white',
    marginHorizontal: 5,
    textTransform: 'uppercase',
  },

  dropVisible: {
    position: 'absolute',
    backgroundColor: 'white',
    height: 100,
    width: 100,
    top: 100,
  },

  image: {
    width: 194,
    height: 50,
  },
  menuBar: {
    flexDirection: 'row',
    marginTop: 70,
    zIndex: 1,
    position: 'absolute',
    marginLeft: 130,
  },

  button: {
    paddingHorizontal: 40,
    backgroundColor: '#0bb975',
    marginLeft: 5,
    zIndex: 100,
  },
  list: {
    flex: 1,
    marginTop: 60,
    marginLeft: 15,
  },

  logout: {
    borderWidth: 1,
    borderColor: '#313c77',
    width: 150,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#313c77',
    position: 'absolute',
    right: 10,
  },

  pickerDimensions: { height: 50, width: 150, marginLeft: 10 },

  '@media (min-width:579) and (max-width: 768)': {
    image: {
      width: 0,
      height: 0,
    },
    column: {
      width: '50%',
    },
    menuBar: {
      marginLeft: 0,
      marginTop: 0,
    },
    button: {
      paddingHorizontal: 0,
      borderRadius: 10,
      top: 10,
      left: 5,
    },
    headerItems: {
      left: 50,
      bottom: 10,
    },
  },
  '@media (min-width:360) and (max-width: 578)': {
    image: {
      width: 0,
      height: 0,
    },
    column: {
      width: '100%',
    },
    menuBar: {
      marginLeft: 0,
      marginTop: 0,
    },
    button: {
      paddingHorizontal: 0,
      borderRadius: 5,
      top: 10,
      left: 5,
    },
    headerItems: {
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      left: 100,
      position: 'fixed',
    },
  },
});

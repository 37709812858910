import React from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { View } from 'react-native';
import PropTypes from 'prop-types';

export class CustomMap extends React.Component {
  static propTypes = {
    title: PropTypes.string,
  };

  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    return (
      <View>
        <Map
          google={this.props.google}
          zoom={14}
          style={{ width: 800, height: 450 }}
        >
          {this.props.title ? (
            <Marker
              onClick={this.onMarkerClick}
              name={'Current location'}
              title={this.props.title}
            >
              <InfoWindow visible={true}>
                <div>
                  <p>
                    Click on the map or drag the marker to select location where
                    the incident occurred
                  </p>
                </div>
              </InfoWindow>
            </Marker>
          ) : null}
        </Map>
      </View>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBXKQ6841WRi6n7QkxjkSBIu3W5vpC61_k',
})(CustomMap);

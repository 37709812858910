import React from 'react';
import { StyleSheet, Text, View, TextInput } from 'react-native-web';
import Button from './Button';
import Api from '../api/Api';
import EStyleSheet from 'react-native-extended-stylesheet';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      repeatPassword: '',
      errorMessage: '',
    };
  }

  changePassword = () => {
    if (this.state.newPassword === this.state.repeatPassword) {
      Api.post('/account', { password: this.state.newPassword })
        .then((res) => {
          if (res.data?.status === 'ok') {
            this.setState({ errorMessage: 'Error, try again later' });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ errorMessage: 'Passwords do not match' });
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.layout}>
          <TextInput
            style={styles.input}
            placeholder="Enter New password"
            placeholderTextColor="#313c77"
            secureTextEntry={true}
            onChangeText={(text) => {
              this.setState({ newPassword: text });
            }}
          />
          <TextInput
            style={styles.input}
            placeholder="Confirm new password"
            placeholderTextColor="#313c77"
            secureTextEntry={true}
            onChangeText={(text) => {
              this.setState({ repeatPassword: text });
            }}
          />
        </View>
        {this.state.errorMessage && (
          <View style={styles.errorMessage}>
            <Text style={styles.errorMessageText}>
              ERROR MESSAGE:{this.state.errorMessage}
            </Text>
          </View>
        )}
        <View style={styles.buttonView}>
          <Button style={styles.button} onPress={this.changePassword} />
        </View>
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  input: {
    fontSize: 18,
    fontFamily: 'ManropeBold',
    borderBottomWidth: 1,
    borderBottomColor: '#313c77',
    paddingBottom: 11,
    paddingRight: 145,
    marginLeft: 140,
    marginTop: 60,
    lineHeight: 20,
    textTransform: 'uppercase',
  },
  layout: {
    padding: '80px',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
  },

  buttonView: {
    flex: 1,
    marginLeft: 440,
    marginTop: 80,
  },

  errorMessage: {
    marginLeft: 20,
  },

  errorMessageText: {
    color: '#bf2c32',
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'ManropeBold',
    lineHeight: 2,
    marginLeft: 80,
  },
  '@media (max-width:780)': {
    layout: {
      flex: 1,
      justifyContent: 'center',
      alignSelf: 'center',
      alignItems: 'center',
      padding: 0,
      margin: 'auto',
    },
    input: {
      padding: 0,
      marginLeft: 10,
      marginRight: 150,
    },

    buttonView: {
      flex: 1,
      marginLeft: 30,
      marginTop: 0,
      bottom: 30,
    },
    errorMessage: {
      marginLeft: 0,
      top: 50,
      right: 50,
    },
  },
});

import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import SubHeader from '../components/SubHeader';
import Api from '../api/Api';

class Statistics extends Component {
  state = {
    closed: 0,
    canceled: 0,
    pending: 0,
    highPriority: 0,
    allFaults: 0,
  };

  getFaults = async () => {
    let res = await Api.get('/client/statistics');
    let closed = res.data.data.closed;
    let canceled = res.data.data.canceled;
    let highPriority = res.data.data.highPriority;
    let allFaults = res.data.data.allFaults;
    let pending = res.data.data.pending;
    this.setState({
      closed: closed,
      canceled: canceled,
      highPriority: highPriority,
      allFaults: allFaults,
      pending: pending,
    });
  };

  componentDidMount() {
    this.getFaults();
  }

  render() {
    console.log(
      this.state.closed,
      this.state.canceled,
      this.state.pending,
      this.state.highPriority,
      this.state.allFaults
    );
    return (
      <View style={styles.scrollContainer}>
        <SubHeader
          name="Statistics"
          description="Lorem ipsum dolor set amet "
        />
        <View style={styles.statisticsRow}>
          <View style={styles.statisticsColumn}>
            <Text style={styles.coloredTitle}>Total</Text>
            <Text style={styles.coloredTitle}>Faults</Text>
            <Text style={styles.coloredNumber}>
              {this.state.allFaults < 10
                ? (this.state.allFaults = '0' + this.state.allFaults)
                : this.state.allFaults}
            </Text>
          </View>
          <View style={styles.statisticsColumn}>
            <Text style={styles.uncoloredTitle}>Pending</Text>
            <Text style={styles.uncoloredTitle}>Faults</Text>
            <Text style={styles.uncoloredNumber}>
              {this.state.pending < 10
                ? (this.state.pending = '0' + this.state.pending)
                : this.state.pending}
            </Text>
          </View>
          <View style={styles.statisticsColumn}>
            <Text style={styles.uncoloredTitle}>High Priority</Text>
            <Text style={styles.uncoloredTitle}>Faults</Text>
            <Text style={styles.uncoloredNumber}>
              {this.state.highPriority < 10
                ? (this.state.highPriority = '0' + this.state.highPriority)
                : this.state.highPriority}
            </Text>
          </View>
          <View style={styles.statisticsColumn}>
            <Text style={styles.uncoloredTitle}>Closed</Text>
            <Text style={styles.uncoloredTitle}>Faults</Text>
            <Text style={styles.uncoloredNumber}>
              {this.state.closed < 10
                ? (this.state.closed = '0' + this.state.closed)
                : this.state.closed}
            </Text>
          </View>
          <View style={styles.statisticsColumn}>
            <Text style={styles.uncoloredTitle}>Canceled</Text>
            <Text style={styles.uncoloredTitle}>Faults</Text>
            <Text style={styles.uncoloredNumber}>
              {this.state.canceled < 10
                ? (this.state.canceled = '0' + this.state.canceled)
                : this.state.canceled}
            </Text>
          </View>
        </View>
        <View style={styles.responseTimeRow}>
          <Text style={styles.coloredTitle}>Response</Text>
          <Text style={styles.coloredTitle}>Time</Text>
          <Text style={styles.coloredNumber}>15H</Text>
        </View>
        <View style={styles.perforanceRow}>
          <Text style={styles.coloredTitle}>Performance</Text>
          <Text style={styles.coloredTitle}>Ratio</Text>
          <Text style={styles.coloredNumber}>89%</Text>
        </View>
      </View>
    );
  }
}

export default Statistics;

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    minHeight: '100vh',
    backgroundColor: '#E7E7E7',
  },
  statisticsRow: {
    flexDirection: 'row',
    marginTop: 50,
    marginLeft: 130,
    flexWrap: 'wrap',
  },
  responseTimeRow: {
    marginTop: 56,
    marginLeft: 130,
  },
  perforanceRow: {
    marginTop: 56,
    marginLeft: 130,
  },
  coloredTitle: {
    fontSize: 24,
    color: '#18ba79',
    fontWeight: 'bold',
    marginHorizontal: 60,
    fontFamily: 'CFDinBold',
    textTransform: 'uppercase',
  },
  coloredNumber: {
    fontSize: 80,
    letterSpacing: 2,
    color: '#18ba79',
    fontWeight: 800,
    fontFamily: 'ManropeExtraBold',
    marginHorizontal: 55,
  },
  uncoloredTitle: {
    color: '#909090',
    fontSize: 24,
    fontWeight: 'bold',
    marginHorizontal: 60,
    fontFamily: 'CFDinBold',
    textTransform: 'uppercase',
  },
  uncoloredNumber: {
    color: '#909090',
    fontSize: 80,
    fontWeight: '1000',
    fontFamily: 'ManropeExtraBold',
    marginHorizontal: 55,
  },
});

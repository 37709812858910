import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
} from '../types/notifications';
import Api from '../../api/Api';

export const fetchNotificationRequest = () => {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST,
  };
};
export const fetchNotificationSuccess = ({ notifications, page }) => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: notifications,
    page,
  };
};
export const fetchNotificationFailure = (error) => {
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    payload: error,
  };
};

export const fetchNotifications = (page = null) => {
  return function (dispatch, getState) {
    let state = getState();
    let newPage = page === null ? state.notificationsReducer.page + 1 : page;
    console.log(state);
    dispatch(fetchNotificationRequest());
    Api.get('account/notifications', { params: { page: newPage } })
      .then((res) => {
        const data = res.data.data;
        dispatch(
          fetchNotificationSuccess({ notifications: data, page: newPage })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

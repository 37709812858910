import { combineReducers } from 'redux';
import sitesReducer from './sites';
import notificationsReducer from './notifications';
import companyReducer from './company';
import notesReducer from './notes';
import causeReducer from './cause';
import worksReducer from './works';
import accountReducer from './account';

export const rootReducer = combineReducers({
  sitesReducer,
  notificationsReducer,
  companyReducer,
  notesReducer,
  causeReducer,
  worksReducer,
  accountReducer,
});

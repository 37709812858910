import React, { Component } from 'react';
import { View } from 'react-native';
import moment from 'moment';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { formatDate, parseDate } from 'react-day-picker/moment';

class DatePickerRange extends Component {
  state = {
    startDate: this.props.startDate || new Date(),
    endDate: this.props.endDate || new Date(),
  };

  handleFromChange = (startDate) => {
    this.setState({ startDate });
    if (typeof this.props.onDatesChange === 'function') {
      this.props.onDatesChange({
        startDate: startDate,
        endDate: this.state.endDate,
      });
    }
  };

  handleToChange = (endDate) => {
    this.setState({ endDate });
    if (typeof this.props.onDatesChange === 'function') {
      this.props.onDatesChange({
        startDate: this.state.startDate,
        endDate: endDate,
      });
    }
  };

  render() {
    const { startDate, endDate } = this.state;
    const modifiers = { start: startDate, end: endDate };
    return (
      <View style={{ flexDirection: 'row' }}>
        <DayPickerInput
          value={startDate}
          placeholder="From"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [startDate, { startDate, endDate }],
            disabledDays: { after: endDate },
            toMonth: endDate,
            modifiers,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />
        <DayPickerInput
          ref={(el) => (this.to = el)}
          value={endDate}
          placeholder="To"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [startDate, { startDate, endDate }],
            disabledDays: { before: startDate },
            modifiers,
            month: startDate,
            fromMonth: startDate,
          }}
          onDayChange={this.handleToChange}
        />
      </View>
    );
  }
}

export default DatePickerRange;

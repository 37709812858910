import React from 'react';
import Api from '../api/Api';
import { View, Text, StyleSheet } from 'react-native';
import CustomMap from '../components/Map';
import SubHeader from '../components/SubHeader';
import CardBlock from '../components/CardBlock';
import { GridLayout, Row, Col } from '../components/Grid';
import moment from 'moment';

export default class Site extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: {},
    };
  }

  getOneSite() {
    Api.get('client/sites/' + this.props.match.params.id)
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        this.setState({ site: data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentDidMount() {
    this.getOneSite();
  }

  render() {
    return (
      <View style={styles.container}>
        <SubHeader name={this.state.site.name} description="lorem ipsum" />
        <View style={styles.gridView}>
          <GridLayout>
            <Row>
              <Col lg={4} md={12}>
                <CardBlock displayTitle="General">
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Name:</Text>
                    <Text style={styles.textValue}>{this.state.site.name}</Text>
                  </View>

                  <View style={styles.wrapper}>
                    <Text style={styles.text}>CompanyId:</Text>
                    <Text style={styles.textValue}>
                      {this.state.site.company_id}
                    </Text>
                  </View>

                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Status:</Text>
                    <Text style={styles.textValue}>
                      {this.state.site.status}
                    </Text>
                  </View>

                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Description:</Text>
                    <Text style={styles.textValue}>
                      {this.state.site.description}
                    </Text>
                  </View>

                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Notes:</Text>
                    <Text style={styles.textValue}>
                      {this.state.site.notes}
                    </Text>
                  </View>
                </CardBlock>
              </Col>
              <Col lg={4} md={12}>
                <CardBlock displayTitle="Site ">
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Power:</Text>
                    <Text style={styles.textValue}>
                      {this.state.site.power + 'KW'}
                    </Text>
                  </View>

                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Installation Date:</Text>
                    <Text style={styles.textValue}>
                      {moment(this.state.site.install_date).format('llll')}
                    </Text>
                  </View>
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Initial Performance:</Text>
                    <Text style={styles.textValue}>
                      {this.state.site.initial_performance}
                    </Text>
                  </View>
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Παροχη:</Text>
                    <Text style={styles.textValue}>
                      {this.state.site.number_paroxi}
                    </Text>
                  </View>
                  <View style={styles.wrapper}>
                    <Text style={styles.text}>Μετρητης:</Text>
                    <Text style={styles.textValue}>
                      {this.state.site.number_metritis}
                    </Text>
                  </View>
                </CardBlock>
              </Col>
            </Row>
          </GridLayout>
          <CustomMap />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: '100vh',
    backgroundColor: '#e7e7e7',
  },

  gridView: {
    alignSelf: 'center',
    marginTop: 50,
  },

  text: {
    fontSize: 16,
    color: '#737477',
    fontFamily: 'CFDinLight',
  },
  textValue: {
    fontSize: 18,
    color: '#414146',
    fontWeight: 800,
    fontFamily: 'Manrope-Regular',
  },
  wrapper: {
    marginVertical: 10,
  },
});

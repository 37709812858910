import {
  FETCH_COMPANY_FAILURE,
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
} from '../types/company';
import builder from './builder';

const initialState = {
  loading: false,
  company: [],
  error: '',
};

const Company = {
  [FETCH_COMPANY_REQUEST]: ({ state, action }) => {
    return {
      ...state,
      loading: true,
    };
  },
  [FETCH_COMPANY_SUCCESS]: ({ state, action }) => {
    return {
      loading: false,
      company: action.payload,
      error: '',
    };
  },
  [FETCH_COMPANY_FAILURE]: ({ state, action }) => {
    return {
      loading: false,
      company: [],
      error: action.payload,
    };
  },
};

export default builder(initialState, Company);

import React from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native-web';
import { connect } from 'react-redux';
import * as sitesActions from '../redux/actions/sites';
import SubHeader from '../components/SubHeader';
import CardBlock from '../components/CardBlock';
import { GridLayout, Row, Col } from '../components/Grid';
import EStyleSheet from 'react-native-extended-stylesheet';

class Sites extends React.Component {
  state = {
    filteredSites: [],
    searchInput: '',
    data: [],
  };

  componentDidMount() {
    this.props.fetchSites();
  }

  renderItem = (item) => {
    return (
      <View style={[{ marginTop: 15, marginLeft: 10, flex: 1 }]}>
        <TouchableOpacity
          style={styles.item}
          onPress={() =>
            this.props.history.push(`/analytics?site_id=${item.id}`)
          }
        >
          <CardBlock
            style={styles.container}
            displayTitle={item.name}
            name="Name"
          >
            <Text style={styles.text}>Power</Text>
            <Text style={styles.textValue}>{item.power + ' KW'}</Text>
            <View style={{ marginTop: 10 }}>
              <Text style={styles.text}>Works</Text>
              <Text style={styles.textValue}>{item.works_count}</Text>
            </View>
          </CardBlock>
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    console.log(this.props.sites);
    return (
      <View>
        <SubHeader name="Sites" description="Lorem ipsum dolores dolres" />
        <View style={styles.gridView}>
          {/* <GridLayout>
            <Row>
              {this.props.sites.map((item) => (
                <Col xl={4} lg={8} md={12}>
                  {this.renderItem(item)}
                </Col>
              ))}
            </Row>
          </GridLayout> */}
          {/* <FlatList
            data={this.props.sites}
            renderItem={({ item }) => this.renderItem(item)}
            keyExtractor={(item) => item.id}
            numColumns={3}
          /> */}
          <View style={styles.sitesRow}>
            {this.props.sites.map((item) => (
              <View>{this.renderItem(item)}</View>
            ))}
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.sitesReducer });

export default connect(mapStateToProps, sitesActions)(Sites);

const styles = EStyleSheet.create({
  item: {
    width: 300,
    marginHorizontal: 40,
    flexWrap: 'wrap',
  },

  text: {
    fontSize: 20,
    color: '#737477',
    fontFamily: 'CFDinLight',
  },
  textValue: {
    fontSize: 24,
    color: '#414146',
    fontWeight: 800,
    fontFamily: 'ManropeExtraBold',
  },

  gridView: {
    minHeight: '72vh',
    paddingLeft: 70,
    paddingVertical: 20,
    backgroundColor: '#E7E7E7',
  },

  sitesRow: {
    flexDirection: 'row',
    marginTop: 50,
    flexWrap: 'wrap',
  },

  textValueAdmin: {
    fontSize: 14,
    color: '#24242a',
    marginTop: 5,
    fontFamily: 'Manrope-Regular',
    fontWeight: 'bold',
  },

  '@media (min-width : 1327 ) and (max-width: 1393)': {
    item: {
      flexWrap: 'wrap',
    },
  },

  '@media (max-width: 781)': {
    gridView: {
      paddingLeft: 0,
    },
    item: {},
  },

  '@media (min-width:1900)': {
    gridView: {
      paddingLeft: 150,
    },
  },

  '@media (min-width:360) and (max-width:380)': {
    sitesRow: {
      right: 25,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
  },

  // '@media (min-width:1200) and (max-width : 1247 ) ': {
  //   gridView: {
  //     paddingHorizontal: 40,
  //   },
  //   column: {
  //     marginHorizontal: 30,
  //   },
  // },
});

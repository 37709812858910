import * as RNLocalize from 'react-native-localize';
import i18n from 'i18n-js';

import en from './../translations/en.json';
import el from './../translations/el.json';

i18n.fallbacks = true;
i18n.defaultLocale = 'en-US';
const locales = RNLocalize.getLocales();

if (Array.isArray(locales)) {
  i18n.locale = locales[0].languageTag;
}

i18n.translations = { en, el };

export default i18n;

import {
  FETCH_ACCOUNT_FAILURE,
  FETCH_ACCOUNT_REQUEST,
  FETCH_ACCOUNT_SUCCESS,
} from '../types/account';
import Api from '../../api/Api';

export const fetchAccountRequest = () => {
  return {
    type: FETCH_ACCOUNT_REQUEST,
  };
};
export const fetchAccountSuccess = (account) => {
  return {
    type: FETCH_ACCOUNT_SUCCESS,
    payload: account,
  };
};
export const fetchAccountFailure = (error) => {
  return {
    type: FETCH_ACCOUNT_FAILURE,
    payload: error,
  };
};

export const fetchAccount = () => {
  return async function (dispatch) {
    dispatch(fetchAccountRequest());
    let res = await Api.get('/account');
    const data = res.data.data;
    dispatch(fetchAccountSuccess(data));
  };
};

import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList,
} from 'react-native-web';
import Api from '../api/Api';
import { GridLayout, Row, Col } from '../components/Grid';
import CardBlock from '../components/CardBlock';
import SubHeader from '../components/SubHeader';
import moment from 'moment';
import EStyleSheet from 'react-native-extended-stylesheet';

export default class Works extends React.Component {
  state = {
    works: [],
    filteredWorks: [],
    searchInput: '',
    sitesPickerValue: null,
    sites: [],
  };

  fetchWorks = () => {
    Api.get('client/works', {
      params: {
        search: this.state.searchInput,
        site_id: this.state.sitesPickerValue,
      },
    })
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        this.setState({ works: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchSites = () => {
    Api.get('client/sites').then((res) => {
      const data = res.data.data;
      this.setState({ sites: data });
      console.log(data);
    });
  };

  // searchTimeout;
  // filterWorks = (text) => {
  //   this.setState({ input: text });
  //   if (this.searchTimeout) {
  //     clearTimeout(this.searchTimeout);
  //   }
  //   this.searchTimeout = setTimeout(() => this.fetchWorks(), 1000);
  // };

  componentDidMount() {
    this.fetchWorks();
    this.fetchSites();
  }

  renderItem = (item) => {
    return (
      <View style={{ marginTop: 15, marginLeft: 10, flex: 1, width: 400 }}>
        <CardBlock
          style={styles.container}
          displayTitle={item.id}
          name="WORK ID"
          titleStyle={{ marginLeft: 12 }}
          nameStyle={{ marginLeft: 12 }}
        >
          <View style={styles.wrapper}>
            <Text style={styles.text}>SITE NAME</Text>
            <Text style={styles.textValue}>{item.site_name}</Text>
          </View>
          <View style={styles.wrapper}>
            <Text style={styles.text}>DATE</Text>
            <Text style={styles.textValue}>
              {moment(item.dateVisited).format('llll')}
            </Text>
          </View>
          <View style={styles.wrapper}>
            <Text style={styles.text}>COMMENTS</Text>
            <Text style={styles.textValueAdmin}>{item.admin_comment}</Text>
          </View>
        </CardBlock>
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <SubHeader name="Works" description={'Lorem ipsum dolor set amet '} />
        <View style={styles.gridView}>
          {/* <GridLayout>
            <Row>
              {this.state.works.map((item) => (
                <Col lg={4} md={12}>
                  {this.renderItem(item)}
                </Col>
              ))}
            </Row>
          </GridLayout> */}
          {/* <FlatList
            data={this.state.works}
            renderItem={({ item }) => this.renderItem(item)}
            keyExtractor={(item) => item.id}
            horizontal={false}
            numColumns={3}
          /> */}
          <View style={styles.worksRow}>
            {this.state.works.map((item) => (
              <View>{this.renderItem(item)}</View>
            ))}
          </View>
        </View>
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  container: {
    flex: 1,
  },
  text: {
    fontSize: 18,
    color: '#737477',
    fontFamily: 'CFDinLight',
  },
  textValue: {
    fontSize: 18,
    color: '#414146',
    fontWeight: 800,
    fontFamily: 'ManropeBold',
  },

  gridView: {
    flex: 1,
    paddingLeft: 70,
    paddingVertical: 20,
    backgroundColor: '#E7E7E7',
  },
  wrapper: {
    marginHorizontal: 10,
    marginVertical: 10,
  },
  textValueAdmin: {
    fontSize: 16,
    color: '#24242a',
    marginTop: 5,
    fontFamily: 'ManropeBold',
    fontWeight: 'bold',
  },

  worksRow: {
    flexDirection: 'row',
    marginTop: 50,
    flexWrap: 'wrap',
  },

  '@media (min-width : 1327 ) and (max-width: 1393)': {
    item: {
      flexWrap: 'wrap',
    },
  },

  '@media (max-width: 840)': {
    gridView: {
      paddingLeft: 0,
    },
  },

  '@media (min-width:1900)': {
    gridView: {
      paddingLeft: 150,
    },
  },
});

import React from 'react';
import LoginScreen from './pages/Login';
import Sites from './pages/Sites';
import Site from './pages/Site';
import Dashboard from './pages/Dashboard';
import Works from './pages/Works';
import Layout from './components/Layout';
import Profile from './pages/Profile';
import Statistics from './pages/Statistics';
import Analytics from './pages/Analytics';
import Work from './pages/Work';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Notifications from './pages/Notifications';
import EStyleSheet from 'react-native-extended-stylesheet';
import RouteWithLayout from './components/RouteWithLayout';
import cookie from 'react-cookies';
import Config from './config/config';
import ResetPassword from './pages/ResetPassword';
import RequestReset from './pages/RequestReset';

function App() {
  EStyleSheet.build();
  Config.API_TOKEN = cookie.load('token');
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/requestreset" component={RequestReset} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <RouteWithLayout
            layout={Layout}
            exact
            path="/dashboard"
            component={Dashboard}
          />

          <RouteWithLayout
            layout={Layout}
            exact
            path="/analytics"
            component={Analytics}
          />
          <RouteWithLayout
            layout={Layout}
            exact
            path="/statistics"
            component={Statistics}
          />
          <RouteWithLayout
            layout={Layout}
            exact
            path="/works"
            component={Works}
          />
          <RouteWithLayout
            layout={Layout}
            exact
            path="/profile"
            component={Profile}
          />
          <RouteWithLayout
            layout={Layout}
            exact
            path="/sites"
            component={Sites}
          />
          {/*<RouteWithLayout*/}
          {/*  layout={Layout}*/}
          {/*  exact*/}
          {/*  path="/works/:id"*/}
          {/*  component={Work}*/}
          {/*/>*/}
          {/*<RouteWithLayout*/}
          {/*  layout={Layout}*/}
          {/*  exact*/}
          {/*  path="/sites/:id"*/}
          {/*  component={Site}*/}
          {/*/>*/}
          <RouteWithLayout
            layout={Layout}
            exact
            path="/notifications"
            component={Notifications}
          />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;

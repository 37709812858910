import {
  FETCH_CAUSE_REQUEST,
  FETCH_CAUSE_SUCCESS,
  FETCH_CAUSE_FAILURE,
} from '../types/cause';
import builder from './builder';

const initialState = {
  loading: false,
  cause: [],
  error: '',
};

const Cause = {
  [FETCH_CAUSE_REQUEST]: () => {
    return {
      loading: true,
    };
  },
  [FETCH_CAUSE_SUCCESS]: ({ state, action }) => {
    return {
      loading: false,
      cause: action.payload,
      error: '',
    };
  },
  [FETCH_CAUSE_FAILURE]: ({ action }) => {
    return {
      loading: false,
      error: action.payload,
    };
  },
};

export default builder(initialState, Cause);

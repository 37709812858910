import React, { PureComponent } from 'react';
import { View, StyleSheet, Text, TextInput } from 'react-native-web';
class InputForm extends PureComponent {
  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{this.props.displayTitle} </Text>
        {!this.props.viewMode ? (
          <TextInput
            value={this.props.value}
            style={[styles.input, this.props.style]}
            multiline={true}
            placeholder={this.props.placeholder}
            onChangeText={this.props.onChangeText}
          />
        ) : (
          <Text>{this.props.value}</Text>
        )}
      </View>
    );
  }
}

export default InputForm;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },

  input: {
    borderWidth: 1,
    width: '50%',
    marginTop: 10,
    padding: 25,
  },

  text: {
    marginTop: 30,
    marginRight: 20,
  },
});

import {
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
} from '../types/notifications';
import builder from './builder';

const initialState = {
  loading: false,
  notifications: [],
  page: 0,
  error: '',
};

const Notifications = {
  [FETCH_NOTIFICATIONS_REQUEST]: () => {
    return {
      loading: true,
    };
  },
  [FETCH_NOTIFICATIONS_SUCCESS]: ({ state, action }) => {
    return {
      loading: false,
      notifications:
        action.page !== 0
          ? state.notifications.concat(action.payload)
          : action.payload,
      page: action.page,
      error: '',
    };
  },
  [FETCH_NOTIFICATIONS_FAILURE]: ({ action }) => {
    return {
      loading: false,
      error: action.payload,
    };
  },
};

export default builder(initialState, Notifications);

import React from 'react';
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Image,
  ImageBackground,
  Dimensions,
} from 'react-native';
import axios from 'axios';
import Button from '../components/Button';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import * as accountActions from '../redux/actions/account';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Config from '../config/config';
import Api from '../api/Api';
import { Link } from 'react-router-dom';
import EStyleSheet from 'react-native-extended-stylesheet';
const windowWidth = Dimensions.get('window').width;

class RequestReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorMessage: '',
    };
  }

  requestReset = async () => {
    await Api.post('account/request-reset', {
      email: this.state.email,
    }).then((res) => {
      console.log(res);
    });
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.leftSideLayout}>
          <View>
            <Image
              style={styles.image}
              source={require('../assets/images/Logo.png')}
            />
          </View>
          <View style={styles.content}>
            <Text style={styles.platformTitle}>Platform Title</Text>
            <Text style={styles.platformTitle}>2 lines Maximum</Text>
            <Text style={styles.platformDescription}>
              Please enter your email
            </Text>
          </View>
          <View style={styles.inputs}>
            <TextInput
              style={styles.input}
              placeholder="EMAIL"
              placeholderTextColor="#495286"
              onChangeText={(text) => {
                this.setState({ email: text });
              }}
            />
          </View>
          <View style={styles.buttonView}>
            <Button style={styles.button} onPress={this.requestReset} />
          </View>
          <View style={{ marginBottom: 150, marginLeft: 125 }}>
            {this.state.errorMessage && (
              <View style={styles.errorMessage}>
                <Text style={styles.errorMessageText}>
                  {this.state.errorMessage}
                </Text>
              </View>
            )}
          </View>
        </View>
        {windowWidth < 1024 ? null : (
          <ImageBackground
            resizeMethod="cover"
            style={styles.rightSideLayout}
            imageStyle={{
              opacity: 0.1,
              resizeMode: 'contain',
              top: 150,
              left: 300,
              position: 'absolute',
              bottom: 0,
            }}
            source={require('../assets/images/eco-roof.png')}
          >
            <TouchableOpacity style={styles.ecoRoof}>
              <Text style={styles.ecoRoofText}>Eco-roof</Text>
            </TouchableOpacity>
          </ImageBackground>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.accountReducer });
export default connect(mapStateToProps, accountActions)(RequestReset);

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    height: '100%',
  },
  leftSideLayout: {
    flex: 1,
    backgroundColor: '#e7e7e7',
  },

  rightSideLayout: {
    flex: 1,
    flexBasis: '30%',
    backgroundColor: '#0bb975',
  },

  content: {
    marginTop: 20,
    marginLeft: 150,
  },

  input: {
    fontFamily: 'Manrope-Regular',
    fontSize: 18,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: '#313c77',
    marginHorizontal: 150,
    marginVertical: 40,
    lineHeight: 52,
    flex: 1,
  },
  image: {
    width: 200,
    height: 54,
    backgroundColor: '#ffffff',
    margin: 150,
    borderLeftRadius: 25,
  },
  buttonView: {
    flex: 1,
    alignSelf: 'flex-end',
    marginTop: 50,
    marginRight: 80,
    padding: 10,
  },
  button: {
    backgroundColor: '#e7e7e7',
  },
  platformTitle: {
    fontSize: 50,
    color: '#313c77',
    fontFamily: 'CFDinBold',
    textTransform: 'uppercase',
    flexWrap: 'wrap',
  },

  platformDescription: {
    marginTop: 5,
    lineHeight: 25,
    color: ' #24242a',
    fontSize: 18,
  },

  ecoRoof: {
    width: 160,
    height: 50,
    borderWidth: 1,
    borderColor: '#ffffff',
    borderRadius: 25,
    marginTop: 80,
    right: 250,
    position: 'absolute',
    textAlignVertical: 'center',
  },
  ecoRoofText: {
    fontSize: 30,
    letterSpacing: 1,
    lineHeight: 5,
    fontWeight: 800,
    color: '#ffffff',
    textAlign: 'center',
    marginTop: 23,
    fontFamily: 'CFDinBold',
  },
  errorMessage: {
    left: 20,
    marginTop: 10,
  },

  errorMessageText: {
    color: '#bf2c32',
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'ManropeBold',
    lineHeight: 2,
  },

  '@media (min-width:767) and (max-width :1024) ': {
    leftSideLayout: {
      flexBasis: '50%',
    },
    rightSideLayout: {
      flexBasis: '50%',
    },
    input: {
      marginHorizontal: 20,
    },
    content: {
      marginLeft: 40,
    },
    buttonView: {
      justifyContent: 'flex-start',
      right: 120,
    },
    forgotPassword: {
      paddingLeft: 0,
      right: 125,
    },
    image: {
      margin: 75,
      marginVertical: 80,
    },
    ecoRoof: {
      borderColor: '#313c77',
      width: 153,
      marginTop: 20,
      right: -2,
      top: 0,
      borderRadius: 0,
    },
    ecoRoofText: {
      color: '#0bb975',
    },
  },
  '@media (min-width:360) and (max-width :766)': {
    container: {
      flex: 1,
      margin: 0,
      padding: 0,
      alignItems: 'center',
    },

    leftSideLayout: {
      flex: 1,
      alignItems: 'center',
      height: '100%',
    },
    rightSideLayout: {
      flexBasis: '0%',
      marginLeft: 0,
      padding: 0,
    },
    ecoRoof: {
      borderColor: '#313c77',
      width: 153,
      marginTop: 20,
      right: -2,
      top: 0,
      borderRadius: 0,
    },
    ecoRoofText: {
      color: '#0bb975',
    },
    input: {
      paddingLeft: 0,
      marginLeft: 0,
      marginHorizontal: 0,
    },
    inputs: {
      flex: 1,
    },
    content: {
      alignItems: 'center',
      marginLeft: 0,
      paddingTop: 0,
    },
    buttonView: {
      marginLeft: 0,
      right: 100,
      padding: 0,
    },
    forgotPassword: {
      marginLeft: 0,
      right: 150,
    },
    image: {
      margin: 0,
      marginVertical: 0,
    },
    errorMessage: {
      flex: 1,
      marginTop: 100,
      left: 0,
      right: 30,
    },
    platformDescription: {
      marginTop: 20,
    },
  },
});

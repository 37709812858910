import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  Image,
  useWindowDimensions,
  Dimensions,
} from 'react-native-web';
import Card from '../components/DashboardCard';
import moment from 'moment';
import { connect } from 'react-redux';
import * as sitesActions from '../redux/actions/sites';
import * as notificationActions from '../redux/actions/notifications';
import * as accountActions from '../redux/actions/account';
import { GridLayout, Row, Col } from '../components/Grid';
import SubHeader from '../components/SubHeader';
import i18n from '../config/i18n';
import Api from '../api/Api';
import EStyleSheet from 'react-native-extended-stylesheet';
const window1 = Dimensions.get('window');

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      date: '',
      previousWorks: [],
      nextWorks: [],
    };
  }

  fetchWorks = () => {
    Api.get('client/works', {
      params: {
        date_announced_start: moment().format('YYYY-MM-DD'),
      },
    })
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        this.setState({ previousWorks: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchWorksEndDate = () => {
    Api.get('client/works', {
      params: {
        date_announced_end: moment().format('YYYY-MM-DD'),
      },
    })
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        this.setState({ nextWorks: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.props.fetchSites();
    this.props.fetchNotifications(0);
    this.fetchWorks();
    this.fetchWorksEndDate();
    var that = this;
    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;

    that.setState({
      date: date + '/' + month,
    });
  }

  renderFlatList = (item) => {
    return <Card name={item.name} power={item.power} id={item.id} />;
  };

  renderPreviousMaintenances = () => {
    return this.state.previousWorks.map((item) => {
      return (
        <View style={styles.subHeaderText}>
          <Text style={[styles.subHeaderTextFont, { marginLeft: 20 }]}>
            {item.id}
          </Text>
          <Text style={styles.subHeaderTextFont}>{item.site_name}</Text>
          <Text style={styles.subHeaderTextFont}>
            {moment(item.date_announced).format('YYYY-M-D h:mm')}
          </Text>
        </View>
      );
    });
  };

  renderNextMaintenances = () => {
    return this.state.nextWorks.map((item) => {
      return (
        <View style={styles.subHeaderText}>
          <Text style={[styles.subHeaderTextFont, { marginLeft: 20 }]}>
            {item.id}
          </Text>
          <Text style={styles.subHeaderTextFont}>{item.site_name}</Text>
          <Text style={styles.subHeaderTextFont}>
            {moment(item.date_announced).format('YYYY-M-D h:mm')}
          </Text>
        </View>
      );
    });
  };

  render() {
    return (
      <ScrollView
        contentContainerStyle={{ justifyContent: 'center' }}
        style={styles.scrollContainer}
        showsHorizontalScrollIndicator={false}
      >
        <SubHeader
          name={`${this.props.account.account.first_name}  ${this.props.account.account.last_name}`}
          description={'Lorem ipsum dolor set amet '}
          row={styles.row}
        >
          {window1.width <= 768 ? null : (
            <View style={[styles.notifications, { marginLeft: 40 }]}>
              {this.props.notifications.notifications
                .slice(0, 1)
                .map((item) => {
                  return (
                    <View style={styles.notificationContainer}>
                      <View style={styles.notificationHeader}>
                        <Text style={styles.notificationHeaderText}>
                          notifications
                        </Text>
                      </View>
                      <View style={styles.notificationContent}>
                        <Text style={styles.notificationTitleText}>
                          {item.title}
                        </Text>
                        <Text style={styles.notificationItemText}>
                          {item.body}
                        </Text>
                      </View>
                    </View>
                  );
                })}
            </View>
          )}
        </SubHeader>
        <View style={styles.weatherAndDate}>
          <View style={styles.dateContainer}>
            <Text style={styles.dateNumberText}>{moment().format('DD')}</Text>
            <Text style={styles.dateMonthText}>{moment().format('MMMM')}</Text>
          </View>
        </View>

        <View
          style={[
            styles.containerPadding,
            { backgroundColor: 'rgba(11,157,100,255)' },
          ]}
        >
          <View style={styles.PVPlantContainer}>
            <Image
              style={styles.PVPlantImage}
              source={require('../assets/images/PVplant.png')}
            />
            <Text style={styles.PVPlantText}>
              PV PLANTS:{this.props.sites.sites.length}
            </Text>
          </View>
          {this.props.sites.sites.map(this.renderFlatList)}
        </View>
        <View
          style={[
            { backgroundColor: '#ffffff', padding: 150 },
            styles.zeroPaddingLayout,
          ]}
        >
          <GridLayout>
            <Row>
              <Col xl={6} style={{ paddingHorizontal: 10 }}>
                <Text style={styles.productionTitle}>Daily Production</Text>
                <Text style={styles.productionNumber}>176,750</Text>
                <Text style={styles.productionPercentage}>+10%</Text>
                <Text style={styles.productionTitle}>Last Year Production</Text>
                <Text style={styles.productionNumber}>46,318,395</Text>
                <Text style={styles.productionPercentage}>+12%</Text>
              </Col>
              <Col xl={6} style={{ paddingHorizontal: 10 }}>
                <Text style={styles.productionTitle}>Last Week Production</Text>
                <Text style={styles.productionNumber}>2,408,830</Text>
                <Text style={styles.productionPercentage}>+10%</Text>
                <Text style={styles.productionTitle}>Total Production</Text>
                <Text style={styles.productionNumber}>216,792,504</Text>
                <Text style={styles.productionPercentage}>+18%</Text>
              </Col>
            </Row>

            <Row>
              <Col xl={6}>
                <View style={[styles.maintanceBox]}>
                  <Text style={[styles.textMaintance]}>
                    Previous maintenances
                  </Text>
                  <View style={styles.header}>
                    <Text style={[styles.headerText]}>WORK ID</Text>
                    <Text style={[styles.headerText]}>PV PLANT NAME</Text>
                    <Text style={[styles.headerText]}>DATE</Text>
                  </View>
                  {this.renderPreviousMaintenances()}
                </View>
              </Col>
              <Col xl={6}>
                <View style={[styles.maintanceBox]}>
                  <Text style={styles.textMaintance}>Next maintenances</Text>
                  <View style={styles.header}>
                    <Text style={[styles.headerText]}>WORK ID</Text>
                    <Text style={[styles.headerText]}>PV PLANT NAME</Text>
                    <Text style={[styles.headerText]}>DATE</Text>
                  </View>
                  {this.renderNextMaintenances()}
                </View>
              </Col>
            </Row>
          </GridLayout>
        </View>
      </ScrollView>
    );
  }
}

const mapStateToProps = (state) => ({
  sites: state.sitesReducer,
  notifications: state.notificationsReducer,
  account: state.accountReducer,
});

const mapActions = {
  fetchNotifications: notificationActions.fetchNotifications,
  fetchSites: sitesActions.fetchSites,
  fetchAccount: accountActions.fetchAccount,
};

export default connect(mapStateToProps, mapActions)(Dashboard);

const styles = EStyleSheet.create({
  scrollContainer: {
    flex: 1,
    overflow: 'hidden',
  },

  weatherAndDate: {
    flexDirection: 'row',
    backgroundColor: '#0a7f51',
    padding: 40,
  },

  dateContainer: {
    marginLeft: 120,
  },

  weatherContainer: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 80,
  },

  dateNumberText: {
    fontSize: 40,
    lineHeight: 36,
    color: '#ffffff',
    fontWeight: 800,
    fontFamily: 'ManropeExtraBold',
    textAlign: 'center',
  },
  dateMonthText: {
    fontSize: 24,
    lineHeight: 36,
    color: '#ffffff',
    fontWeight: 800,
    fontFamily: 'ManropeExtraBold',
    textTransform: 'uppercase',
  },

  temperatureAndLocation: {
    marginLeft: 25,
  },

  temperatureText: {
    fontSize: 20,
    color: '#ffffff',
    fontWeight: 800,
    fontFamily: 'ManropeExtraBold',
  },
  locationText: {
    fontSize: 20,
    color: '#ffffff',
    fontFamily: 'Manrope-Regular',
    letterSpacing: 1,
  },

  image: {
    top: 5,
    width: 75,
    height: 60,
  },

  PVPlantContainer: {
    marginHorizontal: 200,
    marginTop: 80,
    flexDirection: 'row',
  },
  PVPlantImage: {
    width: 80,
    height: 67,
    color: 'rgba(10,127,81,255)',
  },
  PVPlantText: {
    fontFamily: 'CFDinBold',
    color: '#ffffff',
    fontSize: 20,
    marginLeft: 120,
  },
  productionTitle: {
    fontSize: 24,
    letterSpacing: 1,
    color: '#24242a',
    fontFamily: 'CFDinBold',
    marginTop: 10,
    textTransform: 'uppercase',
  },
  productionNumber: {
    fontSize: 44,
    fontWeight: 'bold',
    fontFamily: 'ManropeBold',
    color: '#313c77',
    marginTop: 10,
  },
  productionPercentage: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'ManropeBold',
    color: '#099860',
    marginTop: 10,
  },

  maintanceBox: {
    borderColor: '#099860',
    borderTopWidth: 10,
    borderWidth: 1,
    paddingVertical: 20,
    marginTop: 35,
    marginHorizontal: 10,
  },

  textMaintance: {
    marginTop: 5,
    fontSize: 32,
    color: '#099860',
    fontFamily: 'Manrope-Regular',
    paddingLeft: 20,
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    padding: 20,
    marginTop: 20,
  },

  headerText: {
    flex: 1,
    letterSpacing: 1,
    fontSize: 18,
    color: 'rgba(49,60,119,100)',
    fontFamily: 'CFDinBold',
  },

  subHeaderText: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 20,
    textAlign: 'left',
    marginTop: 10,
  },

  subHeaderTextFont: {
    flex: 1,
    fontFamily: 'ManropeSemiBold',
    textAlign: 'left',
  },

  notificationContainer: {
    borderWidth: 1,
    backgroundColor: '#ffffff',
  },
  notificationHeader: {
    height: 30,
    backgroundColor: '#17ba79',
    borderTopColor: '#17ba79',
    marginBottom: 5,
    paddingRight: 91,
  },
  notificationHeaderText: {
    color: '#ffffff',
    textTransform: 'uppercase',
    textWeight: 'bold',
    fontFamily: 'CFDinBold',
    fontSize: 18,
    marginTop: 5,
    marginLeft: 10,
    height: 13,
    width: 85,
  },

  notificationTitleText: {
    fontSize: 18,
    color: '#313c77',
    textWeight: 'bold',
    fontFamily: 'Manrope',
  },

  notificationItemText: {
    fontSize: 14,
    color: '#313c79',
    fontFamily: 'Manrope',
    marginTop: 5,
    paddingBottom: 15,
  },

  notificationContent: {
    flex: 1,
    marginLeft: 15,
  },
  '@media (min-width:461) and (max-width: 769)': {
    notifications: {
      right: 135,
    },

    row: {
      right: 100,
    },

    containerPadding: {
      paddingBottom: 50,
    },

    PVPlantContainer: {
      marginHorizontal: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
    PVPlantText: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    dateContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    zeroPaddingLayout: {
      padding: 0,
    },
    notificationContainer: {
      top: 110,
      left: 195,
    },

    productionTitle: {
      textAlign: 'center',
    },
    productionNumber: {
      textAlign: 'center',
    },
    productionPercentage: {
      textAlign: 'center',
    },
    dateMonthText: {
      textAlign: 'center',
    },
    dateNumberText: {
      textAlign: 'center',
    },
  },
  '@media (min-width:361) and (max-width:450)': {
    notificationContainer: {},
    row: {
      justifyContent: 'center',
      alignSelf: 'center',
      alignItems: 'center',
    },
    zeroPaddingLayout: {
      padding: 0,
    },
    containerPadding: {
      paddingBottom: 50,
    },
    PVPlantContainer: {
      marginHorizontal: 0,
      textAlign: 'center',
      alignSelf: 'center',
    },
    PVPlantText: {
      marginLeft: 50,
      textAlign: 'center',
    },
    dateContainer: {
      right: 15,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    productionTitle: {
      textAlign: 'center',
    },
    productionNumber: {
      textAlign: 'center',
    },
    productionPercentage: {
      textAlign: 'center',
    },
    maintanceBox: {},
  },

  '@media (max-width:360)': {
    maintanceBox: {},
    notificationContainer: {
      top: 90,
      left: 260,
    },
    row: {
      right: 150,
    },
    zeroPaddingLayout: {
      padding: 0,
    },
    PVPlantContainer: {
      alignSelf: 'center',
      marginHorizontal: 0,
    },
    PVPlantText: {
      marginLeft: 50,
    },
    dateContainer: {
      marginLeft: 0,
      right: 30,
    },
    textMaintance: {
      textAlign: 'center',
    },
    productionTitle: {
      textAlign: 'center',
    },
    productionNumber: {
      textAlign: 'center',
    },
    productionPercentage: {
      textAlign: 'center',
    },
    containerPadding: {
      paddingBottom: 50,
    },
  },
});

import React from 'react';
import { View, Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

export default function CardBlock(props) {
  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.name}>
        <Text style={[styles.nameTextStyle, props.nameStyle]}>
          {props.name}
        </Text>
        <Text style={[styles.title, props.titleStyle]}>
          {props.displayTitle}
        </Text>
      </View>
      <View style={styles.cardContent}>
        <View style={[styles.content]}>{props.children}</View>
      </View>
    </View>
  );
}

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderTopWidth: 10,
    borderColor: '#099860',
    height: 276,
  },

  cardContent: {
    marginLeft: 18,
  },

  content: {
    paddingVertical: 10,
    bottom: 25,
  },

  title: {
    fontSize: 30,
    fontFamily: 'ManropeExtraBold',
    color: '#099860',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginTop: 20,
  },
  name: {
    marginTop: 30,
    marginBottom: 30,
    marginLeft: 15,
  },
  nameTextStyle: {
    fontSize: 18,
    lineHeight: 1,
    fontFamily: 'CFDinLight',
    color: '#737477',
    textTransform: 'uppercase',
  },
});

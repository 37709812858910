import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Text,
  useWindowDimensions,
  Dimensions,
} from 'react-native';
import { useHistory } from 'react-router-dom';
import { GridLayout, Row, Col } from './Grid';
import EStyleSheet from 'react-native-extended-stylesheet';

export default ({ name, power, id }, props) => {
  let history = useHistory();
  const window = useWindowDimensions();

  return (
    <View>
      <TouchableOpacity
        onPress={() => history.push(`/analytics?site_id=${id}`)}
      >
        <View style={styles.body}>
          <Text style={styles.boldText}>
            <Text style={[styles.lightText]}>Model:</Text>
            {name + ' ' + power + ' kw'}
          </Text>
          <View>
            <Text style={styles.boldText}>
              <Text style={[styles.lightText]}>Next Maintenance:</Text>
              16/11/2019
            </Text>
          </View>
        </View>
        {
          window.width <= 768 ? '' : null
          // <View
          //   style={{
          //     height: 120,
          //     flex: 1,
          //     flexDirection: 'row',
          //     justifyContent: 'center',
          //     bottom: 60,
          //   }}
          // >
          //   <Image
          //     style={styles.image}
          //     source={require('../assets/images/Weather.png')}
          //   />
          //   <View
          //     style={{
          //       flexDirection: 'column',
          //       marginLeft: 10,
          //       bottom: 10,
          //     }}
          //   >
          //     <Text style={styles.temperatureText}>19°C</Text>
          //     <Text style={styles.locationText}>Athens</Text>
          //     <Text style={styles.locationText}>Greece</Text>
          //   </View>
          // </View>
        }
      </TouchableOpacity>
    </View>
  );
};

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  body: {
    flex: 1,
    flexWrap: 'wrap',
    paddingLeft: 400,
    marginTop: 50,
  },
  boldText: {
    color: '#ffffff',
    lineHeight: 24,
    fontSize: 18,
    fontFamily: 'ManropeExtraBold',
    letterSpacing: 1,
  },
  lightText: {
    color: '#ffffff',
    fontWeight: 400,
    fontSize: 18,
    fontFamily: 'ManropeLight',
    letterSpacing: 1,
  },

  footer: {
    color: 'black',
  },

  image: {
    width: 60,
    height: 50,
  },
  temperatureText: {
    fontSize: 20,
    color: '#ffffff',
    fontWeight: 800,
    fontFamily: 'Manrope-Regular',
  },
  locationText: {
    fontSize: 20,
    color: '#ffffff',
    fontFamily: 'Manrope-Regular',
    letterSpacing: 1,
  },
  '@media (min-width:450) and (max-width:769)': {
    body: {
      marginTop: 20,
      paddingLeft: 300,
    },
    boldText: {},
    lightText: {},
  },
  '@media (min-width:325) and (max-width:449)': {
    body: {
      marginTop: 20,
      paddingLeft: 0,
    },
    boldText: {
      textAlign: 'center',
    },
    lightText: {
      textAlign: 'center',
    },
  },
});

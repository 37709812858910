import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Text, StyleSheet, Image } from 'react-native';

export default class Button extends React.PureComponent {
  static propTypes = {
    onPress: PropTypes.func,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styleText: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  };

  static defaultProps = {
    onPress: () => {},
    title: '',
    disabled: false,
  };

  renderContent = () => {
    if (this.props.children) {
      return this.props.children;
    } else if (this.props.title) {
      return (
        <Text style={[styles.text, this.props.styleText]}>
          {this.props.title}
        </Text>
      );
    } else {
      return (
        <Image
          style={styles.arrow}
          source={require('../assets/images/button-arrow-black.png')}
        />
      );
    }
  };

  render() {
    return (
      <TouchableOpacity
        activeOpacity={0.7}
        style={[styles.button]}
        onPress={this.props.onPress}
        disabled={this.props.disabled}
      >
        {this.renderContent()}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    borderWidth: 1,
    borderColor: '#313c77',
    width: 150,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  text: {
    color: '#fff',
  },
  arrow: {
    resizeMode: 'contain',
    height: 50,
    width: 50,
  },
});

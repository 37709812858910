import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import Pickers from '../components/Pickers';
import Chart from 'react-google-charts';
import axios from 'axios';
import { Col, GridLayout, Row } from '../components/Grid';
import { Picker } from 'react-native-web';
import DatePickerRange from '../components/DatePickerRange';
import { connect } from 'react-redux';
import * as sitesActions from '../redux/actions/sites';
import moment from 'moment';

class Analytics extends React.Component {
  data = [];
  state = {
    siteId: new URLSearchParams(this.props.location.search).get('site_id'),
    startDate: new Date(),
    endDate: new Date(),
    usageData: [],
    productionData: [],
  };

  async componentDidMount() {
    await this.props.fetchSites();
    this.loadData();
  }

  loadData = async () => {
    let { siteId, startDate, endDate } = this.state;
    if (siteId === null && this.props.sites.sites.length) {
      siteId = this.props.sites.sites[0].id;
    }
    if (siteId && startDate && endDate) {
      let response = await axios.get('https://api.data.mypvsoft.com/v1/data', {
        params: {
          site_id: siteId,
          date_start: moment(startDate).format('YYYY-MM-DD 00:00'),
          date_end: moment(endDate).format('YYYY-MM-DD 23:59'),
        },
      });

      if (response?.data) {
        this.data = response.data.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );

        let usage = [];
        let last_val = null;

        let production = [];
        let production_last = null;
        for (let item of this.data) {
          if (item.name === 'sum') {
            if (usage.length === 0) {
              last_val = item.value;
            }
            usage.push([new Date(item.timestamp), item.value]);
            last_val = item.value;
          } else if (item.name === 'production') {
            if (production.length === 0) {
              production_last = item.value;
            }

            production.push([
              new Date(item.timestamp),
              Math.abs(last_val),
              item.value,
              Math.min(Math.abs(last_val), item.value),
            ]);
            production_last = item.value;
          }
        }

        console.log(production);
        this.setState({ usageData: usage, productionData: production });
      }
    }
  };

  render() {
    // if (!this.state.usageData.length) {
    //   return (
    //     <View>
    //       <Text>Loading...</Text>
    //     </View>
    //   );
    // }
    return (
      <View style={styles.scrollContainer}>
        <View style={styles.container}>
          <View style={styles.head}>
            <Picker
              style={styles.pickerDimensions}
              selectedValue={this.state.siteId}
              onValueChange={(itemValue, itemIndex) =>
                this.setState({ siteId: itemValue }, () => {
                  this.loadData();
                })
              }
            >
              {this.props.sites.sites.map((item) => (
                <Picker.Item label={item.name} value={item.id} />
              ))}
            </Picker>
            <DatePickerRange
              onDatesChange={({ startDate, endDate }) => {
                this.setState({ startDate, endDate }, () => {
                  this.loadData();
                });
              }}
            />
          </View>

          <GridLayout>
            <Row>
              <Col lg={6} md={12}>
                <Chart
                  width={'100%'}
                  height={'300px'}
                  chartType="AreaChart"
                  loader={<div>Loading Chart</div>}
                  data={[['Time', ''], ...this.state.usageData]}
                />
              </Col>
              <Col lg={6}>
                <iframe
                  frameBorder="0"
                  style={{ width: '100%', height: 300 }}
                  src="https://forecast.io/embed/#lat=38.048517&lon=23.7989813&name=Ampelokipoi&units=si&color=#8797b4"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={12}>
                <Chart
                  width={'100%'}
                  height={'300px'}
                  chartType="AreaChart"
                  loader={<div>Loading Chart</div>}
                  data={[
                    [
                      'Time',
                      'Κατανάλωση',
                      'Ισχύς προς το δίκτυο',
                      'Καταναλώθηκε απευθείας',
                    ],
                    ...this.state.productionData,
                  ]}
                  options={{
                    curveType: 'function',
                    backgroundColor: 'transparent',
                    legend: {
                      position: 'top',
                    },
                    series: {
                      0: {
                        color: '#ff0000',
                      },
                      1: {
                        color: '#000',
                        areaOpacity: 0,
                      },
                      2: {
                        color: '#5b5b5b',
                        areaOpacity: 0.8,
                      },
                    },
                  }}
                />
              </Col>
              <Col lg={6} md={12}>
                <Chart
                  width={'100%'}
                  height={'300px'}
                  chartType="AreaChart"
                  loader={<div>Loading Chart</div>}
                  data={[
                    [
                      'Time',
                      'Κατανάλωση',
                      'Ισχύς προς το δίκτυο',
                      'Καταναλώθηκε απευθείας',
                    ],
                    ...this.state.productionData,
                  ]}
                  options={{
                    curveType: 'function',
                    legend: {
                      position: 'top',
                    },
                    backgroundColor: 'transparent',
                    series: {
                      0: {
                        color: '#000',
                        areaOpacity: 0,
                      },
                      1: {
                        color: '#00ff00',
                      },

                      2: {
                        color: '#5b5b5b',
                        areaOpacity: 0.8,
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </GridLayout>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ sites: state.sitesReducer });

export default connect(mapStateToProps, sitesActions)(Analytics);

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: 2,
    backgroundColor: 'white',
    marginTop: 2,
  },
  container: {
    flex: 1,
    margin: 15,
    // alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    fleWrap: 'wrap',
    width: '100%',
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },

  box: {
    width: '100%',
    alignSelf: 'start',
    padding: 50,
  },

  head: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    margin: 5,
    zIndex: 9999,
  },
  pickerDimensions: { height: 50 },
});

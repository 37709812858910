import {
  FETCH_NOTES_FAILURE,
  FETCH_NOTES_REQUEST,
  FETCH_NOTES_SUCCESS,
  ADD_NOTE,
  DELETE_NOTE,
  EDIT_NOTE,
} from '../types/notes';
import builder from './builder';

const initialState = {
  loading: false,
  notes: [],
  error: '',
  page: 0,
};

const Notes = {
  [FETCH_NOTES_REQUEST]: ({ state, action }) => {
    return {
      loading: true,
    };
  },
  [FETCH_NOTES_SUCCESS]: ({ state, action }) => {
    return {
      loading: false,
      notes:
        action.payload.page !== 0
          ? state.notes.concat(action.payload.notes)
          : action.payload.notes,
      error: '',
      page: action.payload.page,
    };
  },
  [FETCH_NOTES_FAILURE]: ({ state, action }) => {
    return {
      loading: false,
      error: action.payload,
    };
  },
  [ADD_NOTE]: ({ state, action }) => {
    return {
      notes: [action.payload, ...state.notes],
      error: '',
    };
  },

  [DELETE_NOTE]: ({ state, action }) => {
    const filteredArray = state.notes.filter(
      (item) => item.note_id !== action.payload.note_id
    );

    return {
      notes: filteredArray,
    };
  },
  [EDIT_NOTE]: ({ state, action }) => {
    return {
      notes: [action.payload, ...state.notes],
    };
  },
};

export default builder(initialState, Notes);

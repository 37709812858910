import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '40%',
  },
};

const WebModal = ({ isVisible, onBackdropPress, children }) => {
  return (
    <Modal
      style={customStyles}
      isOpen={isVisible}
      onRequestClose={onBackdropPress}
    >
      {children}
    </Modal>
  );
};

export default WebModal;
